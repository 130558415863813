import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Container, Segment, Header, Icon } from "semantic-ui-react";

import "./App.css";
import PageOne from "./Pages/PageOne";
import About from "./Pages/About";
import Creator from "./Pages/Creator";
import ResponsiveContainer from "./Global/ResponsiveContainer";

const renderLoader = () => {
  return (
    <Container fluid textAlign="center">
      <Segment fluid="true" basic style={{ paddingTop: "20vh" }}>
        <Header as="h1" icon>
          <Icon name="sync" loading />
          Loading
        </Header>
      </Segment>
    </Container>
  );
};

function App() {
  return (
    <React.Suspense fallback={renderLoader()}>
      <Router>
        <ResponsiveContainer>
          <Switch>
            <Route path="/" exact component={PageOne} />
            <Route path="/about" exact component={About} />
            <Route path="/creator" exact component={Creator} />
          </Switch>
        </ResponsiveContainer>
      </Router>
    </React.Suspense>
  );
}

export default App;
