import React, { Component } from 'react';
import {Segment} from 'semantic-ui-react'

export default class About extends Component {
    render() {
        return(
            <Segment basic>
                This will be the About Page
            </Segment>
        )
    }
}
         
          