import { isMobile } from "react-device-detect";
import React, { Component } from "react";
import {
  Segment,
  Form,
  Input,
  Grid,
  Header,
  Responsive,
} from "semantic-ui-react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const getWidth = () => {
  const isSSR = typeof window === "undefined";
  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth;
};

const INITIAL_STATE = {
  roadwidth: 10,
  roadLength: 10000,
  InitialRoadDepth: 0.25,
  roadporosity: 0.4,
  roadmaintenance: 2,
  annualDailyCarPassage: 200,
  gravelDensity: 1800,
  rateOfLossPerMPerAnnualDailyCarPassage: 0.56,
  timesteps: 12,
  timescale: 1,
  mm25: 15,
  mm19: 20,
  mm475: 17,
  mm236: 23,
  mm0425: 15,
  mm0075: 12,
  loading: false,
  error: false,
  success: false,
  data: [],
  mobile: isMobile,
  window_width: getWidth(),
};

export default class GravelSizeBreakdown extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  handleChange = (e, { name, value }) => this.setState({ [name]: value });
  handleSubmit = () => {
    const {
      roadwidth,
      roadLength,
      InitialRoadDepth,
      roadporosity,
      roadmaintenance,
      annualDailyCarPassage,
      gravelDensity,
      rateOfLossPerMPerAnnualDailyCarPassage,
      timesteps,
      timescale,
      mm25,
      mm19,
      mm475,
      mm236,
      mm0425,
      mm0075,
    } = this.state;
    let dT = 1 / timesteps;

    let Fmm25 = mm25 / 100,
      Fmm19 = mm19 / 100,
      Fmm475 = mm475 / 100,
      Fmm236 = mm236 / 100,
      Fmm0425 = mm0425 / 100,
      Fmm0075 = mm0075 / 100;

    let mm25_New_Distribution = mm25 / 100,
      mm19_New_Disribution = mm19 / 100,
      mm4_75_New_Distribution = mm475 / 100,
      mm2_36_New_Distribution = mm236 / 100,
      mm0_245_New_Distribution = mm0425 / 100,
      mm0_075_New_Distribution = mm0075 / 100;

    const iterations = timescale * timesteps + 1;
    const annualGravelLossRate =
      (rateOfLossPerMPerAnnualDailyCarPassage *
        roadLength *
        annualDailyCarPassage) /
      gravelDensity;
    var roadDepth = InitialRoadDepth;
    var roadVolume,
      materialLost_RoadDepth = 0,
      materialLost_cumulative = 0,
      percentNewMaterial = 1,
      PercentOldMaterial = 0,
      materialLostAsDust = 0;
    const dataSet = [];
    const maintenanceInterval = timesteps / roadmaintenance;
    const rateOfChangeInComposition = 0.36 / timesteps;
    roadVolume = InitialRoadDepth * roadLength * roadwidth * (1 - roadporosity);

    // calculate the Flux of gravel sizes
    let Flux = {
      Fmm25: mm25 / 100,
      Fmm19: mm19 / 100,
      Fmm475: mm475 / 100,
      Fmm236: mm236 / 100,
      Fmm0425: mm0425 / 100,
      Fmm0075: mm0075 / 100,
    };
    let mmInputs = {
      mm25_Input:
        Flux.Fmm25 * PercentOldMaterial +
        mm25_New_Distribution * percentNewMaterial,
      mm19_Input:
        Flux.Fmm19 * PercentOldMaterial +
        mm19_New_Disribution * percentNewMaterial,
      mm4_75_Input:
        Flux.Fmm475 * PercentOldMaterial +
        mm4_75_New_Distribution * percentNewMaterial,
      mm2_36_Input:
        Flux.Fmm236 * PercentOldMaterial +
        mm2_36_New_Distribution * percentNewMaterial,
      mm0_425_Input:
        Flux.Fmm0425 * PercentOldMaterial +
        mm0_245_New_Distribution * percentNewMaterial,
      mm0_075_Input:
        Flux.Fmm0075 * PercentOldMaterial +
        mm0_075_New_Distribution * percentNewMaterial,
    };

    let Percentage_of_Roadway_per_Particle_Size = {
      mm25: mmInputs.mm25_Input * roadVolume,
      mm19: mmInputs.mm19_Input * roadVolume,
      mm475: mmInputs.mm4_75_Input * roadVolume,
      mm236: mmInputs.mm2_36_Input * roadVolume,
      mm0425: mmInputs.mm0_425_Input * roadVolume,
      mm0075: mmInputs.mm0_075_Input * roadVolume,
    };
    // //Add Initial Variables to the 0th Time step
    // dataSet.push({
    //   name: `Month 0`,
    //   "Gravel Loss Rate": annualGravelLossRate / roadVolume,
    //   "Road Depth": Number(roadDepth).toFixed(10),
    //   "Road Volume": roadVolume,
    //   "Depth Lost": materialLost_cumulative,
    //   "Percent New Material": percentNewMaterial,
    //   "Percent Old Material": PercentOldMaterial,
    //   "Material Lost As Dust": materialLostAsDust,
    //   ...Flux,
    //   ...Percentage_of_Roadway_per_Particle_Size,
    // });
    let gravelLossRate = annualGravelLossRate / roadVolume;
    let materialLost = (roadDepth * gravelLossRate) / timesteps;

    //  Begin the iterations of all timesteps
    for (let i = 0; i < iterations; i++) {
      dataSet.push({
        name: `Month ${i}`,
        "Gravel Loss Rate": gravelLossRate,
        "Road Depth": Number(roadDepth).toFixed(10),
        "Road Volume": Number(roadVolume),
        "Depth Lost": materialLost_RoadDepth,
        "Cumulative Depth Lost": materialLost_cumulative,
        "Percent New Material": percentNewMaterial,
        "Percent Old Material": PercentOldMaterial,
        "Material Lost As Dust": materialLostAsDust,
        ...Flux,
        ...Percentage_of_Roadway_per_Particle_Size,
      });
      // *** Beginning of Timestep calculations

      //   maintenance interval is calculated, where if maintenance occurs, material is added
      if (i % maintenanceInterval === 0) {
        roadDepth += InitialRoadDepth - roadDepth;
      }

      //    Handling the complete turnover of material
      if (InitialRoadDepth - materialLost_RoadDepth < 0) {
        materialLost_RoadDepth = 0;
      }

      // Material lost 
      materialLost = (roadDepth * gravelLossRate) / timesteps;

      //    Cumulative Materials Lost
      materialLost_cumulative += materialLost;
      materialLost_RoadDepth += materialLost;

      //   accounting for complete turnover with non-negative protection
      if (roadDepth - materialLost >= 0) {
        roadDepth -= materialLost;
      }

      // *** ADD IN Total_Mass_C02_Converted(t) CALCULATION *** //

      // Gravel Size Flux Breakdown Calculation

      Flux["Fmm25"] = Number(
        (Fmm25 -=
          (Fmm25 * rateOfChangeInComposition +
            Fmm25 * rateOfChangeInComposition +
            Fmm25 * rateOfChangeInComposition +
            Fmm25 * rateOfChangeInComposition +
            Fmm25 * rateOfChangeInComposition) *
          dT)
      ).toFixed(12);

      Flux["Fmm19"] = Number(
        (Fmm19 +=
          (Fmm25 * rateOfChangeInComposition -
            Fmm19 * rateOfChangeInComposition -
            Fmm19 * rateOfChangeInComposition -
            Fmm19 * rateOfChangeInComposition -
            Fmm19 * rateOfChangeInComposition) *
          dT)
      ).toFixed(12);

      Flux["Fmm475"] = Number(
        (Fmm475 +=
          (Fmm25 * rateOfChangeInComposition +
            Fmm19 * rateOfChangeInComposition -
            Fmm475 * rateOfChangeInComposition -
            Fmm475 * rateOfChangeInComposition -
            Fmm475 * rateOfChangeInComposition) *
          dT)
      ).toFixed(12);

      Flux["Fmm236"] = Number(
        (Fmm236 +=
          (Fmm25 * rateOfChangeInComposition +
            Fmm19 * rateOfChangeInComposition +
            Fmm475 * rateOfChangeInComposition -
            Fmm236 * rateOfChangeInComposition -
            Fmm236 * rateOfChangeInComposition) *
          dT)
      ).toFixed(12);

      Flux["Fmm0425"] = Number(
        (Fmm0425 +=
          (Fmm25 * rateOfChangeInComposition +
            Fmm19 * rateOfChangeInComposition +
            Fmm475 * rateOfChangeInComposition +
            Fmm236 * rateOfChangeInComposition -
            Fmm0425 * rateOfChangeInComposition) *
          dT)
      ).toFixed(12);

      Flux["Fmm0075"] = Number(
        (Fmm0075 +=
          (Fmm25 * rateOfChangeInComposition +
            Fmm19 * rateOfChangeInComposition +
            Fmm475 * rateOfChangeInComposition +
            Fmm236 * rateOfChangeInComposition +
            Fmm0425 * rateOfChangeInComposition) *
          dT)
      ).toFixed(12);

    //   //   maintenance interval is calculated, where if maintenance occurs, material is added
    //   if (i % maintenanceInterval === 0) {
    //     roadDepth += InitialRoadDepth - roadDepth;
    //   }

      //   Road PreRoadVolume at the end of timestep is calculated
      roadVolume = Number(
        roadDepth * roadLength * roadwidth * (1 - roadporosity)
      ).toFixed(7);

      //    Gravel Loss rate calculated using pre-loss road volume
      gravelLossRate = annualGravelLossRate / roadVolume;

      //    Calculate the % New Material
      percentNewMaterial =
        (InitialRoadDepth - materialLost_RoadDepth) / InitialRoadDepth;

      //    Calculate the % Old Material
      PercentOldMaterial = 1 - percentNewMaterial;

      mmInputs["mm25_Input"] = Number(
        Number(Flux.Fmm25) * PercentOldMaterial +
          mm25_New_Distribution * percentNewMaterial
      ).toFixed(12);
      mmInputs["mm19_Input"] = Number(
        Number(Flux.Fmm19) * PercentOldMaterial +
          mm19_New_Disribution * percentNewMaterial
      ).toFixed(12);
      mmInputs["mm4_75_Input"] = Number(
        Number(Flux.Fmm475) * PercentOldMaterial +
          mm4_75_New_Distribution * percentNewMaterial
      ).toFixed(12);
      mmInputs["mm2_36_Input"] = Number(
        Number(Flux.Fmm236) * PercentOldMaterial +
          mm2_36_New_Distribution * percentNewMaterial
      ).toFixed(12);
      mmInputs["mm0_425_Input"] = Number(
        Number(Flux.Fmm0425) * PercentOldMaterial +
          mm0_245_New_Distribution * percentNewMaterial
      ).toFixed(12);
      mmInputs["mm0_075_Input"] = Number(
        Number(Flux.Fmm0075) * PercentOldMaterial +
          mm0_075_New_Distribution * percentNewMaterial
      ).toFixed(12);

      // Calculate the percentage of Roadway by Particle size 
      Percentage_of_Roadway_per_Particle_Size["mm25"] = Number(
        Number(mmInputs.mm25_Input) * Number(roadVolume)
      ).toFixed(8);
      Percentage_of_Roadway_per_Particle_Size["mm19"] = Number(
        Number(mmInputs.mm19_Input) * Number(roadVolume)
      ).toFixed(8);
      Percentage_of_Roadway_per_Particle_Size["mm475"] = Number(
        Number(mmInputs.mm4_75_Input) * Number(roadVolume)
      ).toFixed(8);
      Percentage_of_Roadway_per_Particle_Size["mm236"] = Number(
        Number(mmInputs.mm2_36_Input) * Number(roadVolume)
      ).toFixed(8);
      Percentage_of_Roadway_per_Particle_Size["mm0425"] = Number(
        Number(mmInputs.mm0_425_Input) * Number(roadVolume)
      ).toFixed(8);
      Percentage_of_Roadway_per_Particle_Size["mm0075"] = Number(
        Number(mmInputs.mm0_075_Input) * Number(roadVolume)
      ).toFixed(8);

      // Caluclate Material Lost as Dust
      materialLostAsDust +=
        materialLost * roadwidth * roadLength * (1 - roadporosity);

    }
    // Set the State with the Created DataSet
    this.setState((prevState, prevProps) => {
      return {
        data: dataSet,
      };
    });
  };

  render() {
    const {
      loading,
      error,
      success,
      // rateOfLossPerMPerAnnualDailyCarPassage,
      data,
      mobile,
      window_width,
    } = this.state;
    const width = mobile ? window_width : 500;

    console.log(this.state);

    return (
      <Segment raised textAlign="center">
        <h2>Road Characteristics</h2>
        <Form
          onSubmit={this.handleSubmit}
          loading={loading}
          success={success}
          error={error}
        >
          <Form.Group widths="equal">
            <Form.Field
              control={Input}
              type="number"
              min="0"
              label="Road Length (m)"
              placeholder="10000"
              name="roadLength"
              value={this.state.roadLength}
              onChange={this.handleChange}
            />
            <Form.Field
              control={Input}
              type="number"
              min="0"
              label="Road Width (m)"
              placeholder="10"
              name="roadwidth"
              value={this.state.roadwidth}
              onChange={this.handleChange}
            />
            <Form.Field
              control={Input}
              type="number"
              min="0"
              step="0.01"
              label="Road Depth (m)"
              placeholder="0.25"
              name="InitialRoadDepth"
              value={this.state.InitialRoadDepth}
              onChange={this.handleChange}
            />
            <Form.Field
              control={Input}
              type="number"
              step="0.1"
              max="1"
              min="0"
              label="Road Porosity"
              placeholder="0.4"
              name="roadporosity"
              value={this.state.roadporosity}
              onChange={this.handleChange}
            />
            <Form.Field
              control={Input}
              type="number"
              min="0"
              max="52"
              label="Time Steps / Year"
              placeholder="12"
              name="timesteps"
              value={this.state.timesteps}
              onChange={this.handleChange}
            />
            <Form.Field
              control={Input}
              type="number"
              min="0"
              label="Time Scale (years)"
              placeholder="1"
              name="timescale"
              value={this.state.timescale}
              onChange={this.handleChange}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              control={Input}
              type="number"
              min="0"
              max="12"
              label="Road Maintenance Frequency"
              placeholder="2"
              name="roadmaintenance"
              value={this.state.roadmaintenance}
              onChange={this.handleChange}
            />
            <Form.Field
              control={Input}
              type="number"
              min="1"
              label="Annual Daily Car Passage"
              placeholder="200"
              name="annualDailyCarPassage"
              value={this.state.annualDailyCarPassage}
              onChange={this.handleChange}
            />
            <Form.Field
              control={Input}
              type="number"
              min="1"
              label="Gravel Density"
              placeholder="200"
              name="gravelDensity"
              value={this.state.gravelDensity}
              onChange={this.handleChange}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              control={Input}
              type="number"
              min="0"
              max="100"
              step="1"
              label="mm25"
              placeholder={this.state.mm25}
              name="mm25"
              value={this.state.mm25}
              onChange={this.handleChange}
            />
            <Form.Field
              control={Input}
              type="number"
              min="0"
              max="100"
              step="1"
              label="mm19"
              placeholder={this.state.mm19}
              name="mm19"
              value={this.state.mm19}
              onChange={this.handleChange}
            />
            <Form.Field
              control={Input}
              type="number"
              min="0"
              max="100"
              step="1"
              label="mm4.75"
              placeholder={this.state.mm475}
              name="mm475"
              value={this.state.mm475}
              onChange={this.handleChange}
            />
            <Form.Field
              control={Input}
              type="number"
              min="0"
              max="100"
              step="1"
              label="mm2.36"
              placeholder={this.state.mm236}
              name="mm236"
              value={this.state.mm236}
              onChange={this.handleChange}
            />
            <Form.Field
              control={Input}
              type="number"
              min="0"
              max="100"
              step="1"
              label="mm0.425"
              placeholder={this.state.mm0425}
              name="mm0425"
              value={this.state.mm0425}
              onChange={this.handleChange}
            />

            <Form.Field
              control={Input}
              type="number"
              min="0"
              max="100"
              step="1"
              label="mm0.075"
              placeholder={this.state.mm0075}
              name="mm0075"
              value={this.state.mm0075}
              onChange={this.handleChange}
            />
          </Form.Group>
          <Form.Button content="Run Calculation" />
        </Form>
        <Header className="m-6">Graphs</Header>
        <Grid columns={2} stackable>
          <Grid.Row>
            <Grid.Column textAlign="center" verticalAlign="middle">
              <LineChart
                width={width}
                height={300}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: mobile ? 0 : 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="Road Depth" stroke="#8884d8" />
                <Line type="monotone" dataKey="Depth Lost" stroke="#82ca9d" />
                <Line
                  type="monotone"
                  dataKey="Gravel Loss Rate"
                  stroke="pink"
                />
              </LineChart>
            </Grid.Column>
            <Grid.Column textAlign="center" verticalAlign="middle">
              <LineChart
                width={width}
                height={300}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: mobile ? 0 : 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="Road Volume" stroke="#1D3430" />
                <Line
                  type="monotone"
                  dataKey="Material Lost As Dust"
                  stroke="#82ca9d"
                />
              </LineChart>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <LineChart
                width={width}
                height={300}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: mobile ? 0 : 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="Percent New Material"
                  stroke="pink"
                />
                <Line
                  type="monotone"
                  dataKey="Percent Old Material"
                  stroke="#8884d8"
                />
              </LineChart>
            </Grid.Column>
            <Grid.Column>
              <LineChart
                width={width}
                height={300}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: mobile ? 0 : 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="mm25" stroke="pink" />
                <Line type="monotone" dataKey="mm19" stroke="blue" />
                <Line type="monotone" dataKey="mm475" stroke="red" />
                <Line type="monotone" dataKey="mm236" stroke="green" />
                <Line type="monotone" dataKey="mm0425" stroke="green" />
                <Line type="monotone" dataKey="mm0075" stroke="grey" />
              </LineChart>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}
