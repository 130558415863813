import React, { Component } from 'react';
import {Segment} from 'semantic-ui-react'
import Gravel from '../Components/GravelSizeBreakdownReorg'

export default class PageOne extends Component {
    render() {
        return(
            <Segment basic>
                <Gravel timeStep="200" />
            </Segment>
        )
    }
}
         
          