import React, { Component } from 'react';
import {Segment} from 'semantic-ui-react'

export default class Creator extends Component {
    render() {
        return(
            <Segment basic>
                This will be the Creator
            </Segment>
        )
    }
}
         
          