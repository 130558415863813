import { createMedia } from "@artsy/fresnel";
import * as React from "react";
import {
  Container,
  Icon,
  Image,
  Menu,
  Segment,
  Sidebar,
  Visibility,
} from "semantic-ui-react";
import geode from "../Geode.svg";
import { NavLink, withRouter } from "react-router-dom";
import SiteFooter from "./SiteFooter";

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
});

const DesktopContainer = (props) => {
  const hideFixedMenu = () => setFixed(false);
  const showFixedMenu = () => setFixed(true);
  const [fixed, setFixed] = React.useState(false);
  const { children } = props;
  return (
    <Media greaterThan="mobile">
      <Visibility
        once={false}
        onBottomPassed={showFixedMenu}
        onBottomPassedReverse={hideFixedMenu}
      >
        <Segment inverted textAlign="center" vertical>
          <Menu
            fixed={fixed ? "top" : null}
            inverted={true}
            pointing={!fixed}
            secondary={!fixed}
            size="large"
          >
            <Container>
              <Menu.Item as={NavLink} to="/" name="Home" exact content="Home" />
              <Menu.Item
                as={NavLink}
                to="/about"
                name="About"
                exact
                content="About"
              />
              <Menu.Item
                position="right"
                as={NavLink}
                name="Creator"
                to="/creator"
                content={
                  <React.Fragment>
                    <Image
                      size={fixed ? "mini" : "tiny"}
                      src={geode}
                      title="Geode by Lluisa Iborra from the Noun Project"
                    />
                    Miles
                  </React.Fragment>
                }
              />
            </Container>
          </Menu>
        </Segment>
      </Visibility>
      {children}
      <SiteFooter />
    </Media>
  );
};

const MobileContainer = (props) => {
  const [sidebarOpened, setSidebarOpened] = React.useState(false);
  const handleSidebarHide = () => setSidebarOpened(false);
  const handleToggle = () => setSidebarOpened(true);

  const { children } = props;

  return (
    <Media as={Sidebar.Pushable} at="mobile">
      <Sidebar.Pushable>
        <Sidebar
          as={Menu}
          animation="overlay"
          inverted
          onHide={handleSidebarHide}
          vertical
          visible={sidebarOpened}
          width="thin"
        >
          <Menu.Item as={NavLink} to="/" name="Home" exact content="Home" />
          <Menu.Item
            as={NavLink}
            to="/about"
            name="About"
            exact
            content="About"
          />
        </Sidebar>
        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment
            inverted
            textAlign="center"
            style={{ minHeight: 100, padding: "1em 0em" }}
            vertical
          >
            <Container>
              <Menu inverted pointing secondary size="large">
                <Menu.Item
                  onClick={handleToggle}
                  content={<Icon name="sidebar" size="large" />}
                />
                <Menu.Item
                  position="right"
                  as={NavLink}
                  name="Creator"
                  to="/creator"
                  content={
                    <React.Fragment>
                      <Image
                        size="mini"
                        src={geode}
                        title="Geode by Lluisa Iborra from the Noun Project"
                      />
                      Miles
                    </React.Fragment>
                  }
                />
              </Menu>
            </Container>
          </Segment>
          {children}
          <SiteFooter />
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </Media>
  );
};

const ResponsiveContainer = (props) => (
  <MediaContextProvider>
    <DesktopContainer {...props} />
    <MobileContainer {...props} />
  </MediaContextProvider>
);

export default withRouter(ResponsiveContainer);
