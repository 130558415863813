import * as React from "react";
import { Segment } from "semantic-ui-react";

const SiteFooter = React.memo((props) => {
  return (
    <Segment basic textAlign="center">
      FOOTER
    </Segment>
  );
});
export default SiteFooter;
